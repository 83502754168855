<h2>My Stories</h2>
<ul class="stories">
  <li *ngFor="let story of stories | paginate: paginatorConfig">
    <a routerLink="/stories/{{story.id}}">
      <span class="badge">{{story.id}}</span> {{story.name}}
    </a>
  </li>
</ul>

<div class="float-right">
  <pagination-controls id="{{paginatorConfig.id}}" (pageChange)="getStories($event)"></pagination-controls>
</div>
