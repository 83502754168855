<h1>Husol Story</h1>
<h2>Every life has a story if you bother to read it</h2>
<div id="downloadBtn" >
    <a target="" href="#">
        <img src="assets/img/appStore-tmp.png" class="opacity-40 button" alt="Tải Husol Story với AppStore" title="Tải Husol Story với AppStore">
    </a>
    <a target="_blank" href="https://play.google.com/store/apps/details?id=org.husol.story">
        <img src="assets/img/ggPlay.png" class="button" alt="Tải Husol Story với GooglePlay" title="Tải Husol Story với GooglePlay">
    </a>
</div>
<img id="book" src="assets/img/sach-truyen-husol-story.svg" alt="Trải nghiệm đọc truyện hay cùng Husol Story" title="Husol Story ">
