<h2>Top Stories</h2>
<div class="grid-pad">
  <a *ngFor="let story of stories" class="col-1-4"
     routerLink="/stories/{{story.id}}">
    <div class="module story">
      <h4>{{story.name}}</h4>
    </div>
  </a>
</div>
<div class="clear"></div>
<div class="text-center">
  <button mat-raised-button color="primary" (click)="showSuccessNotification()">Show Success Message</button>
  &nbsp;
  <button mat-raised-button color="warn" (click)="showErrorNotification()">Show Error Message</button>
</div>
